import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ArrowR from '@material-ui/icons/ArrowForward'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import Hr from '../components/Hr'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledColocation,
  header,
  colocationItems,
  colocationForms,
  IdProtectList,
  IdProtectBox
} from '../components/styles/Colocation.styles'
import { termosBox } from '../components/styles/Contact.styles'
import ColocationForm from '../components/ContactForm2'

const fields = [
  {
    label: 'Nome Completo',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Como podemos ajudar?',
    name: 'your-message',
    type: 'text',
    multiline: true,
    rows: 6,
    required: true
  },
  {
    render: () => {
      return (
        <span
          style={{
            display: 'block',
            marginTop: 16,
            marginBottom: 16
          }}
        >
          CONFIGURAÇÃO DO SERVIDOR (OPCIONAL)
        </span>
      )
    }
  },
  {
    label: 'Configuração do servidor',
    name: 'your-server',
    type: 'select',
    options: [
      { value: 'Sim', label: 'Sim' },
      { value: 'Não', label: 'Não' }
    ],
    required: false
  },
  {
    label: 'Espaço',
    name: 'your-space',
    type: 'select',
    options: [
      { value: '1U, Um servidor', label: '1U, Um servidor' },
      { value: '2U, Um servidor', label: '2U, Um servidor' },
      { value: '3U, Um servidor', label: '3U, Um servidor' },
      { value: '4U, Um servidor', label: '4U, Um servidor' },
      { value: '1/4 Rack, com trava', label: '1/4 Rack, com trava' },
      { value: '1/2 Rack, com trava', label: '1/2 Rack, com trava' },
      { value: 'Full Rack, com trava', label: 'Full Rack, com trava' },
      { value: 'Múltiplos Racks', label: 'Múltiplos Racks' },
      { value: 'Espaço de Cage', label: 'Espaço de Cage' }
    ],
    required: false
  },
  {
    label: ' Consumo médio de banda',
    name: 'your-bandwith',
    type: 'select',
    options: [
      { value: '0-10 Mb/s', label: '0-10 Mb/s' },
      { value: '11-100 Mb/s', label: '11-100 Mb/s' },
      { value: '100++ Mb/s', label: '100++ Mb/s' }
    ],
    required: false
  },
  {
    label: 'A/B Power',
    name: 'your-abpower',
    type: 'select',
    options: [
      { value: 'Sim', label: 'Sim' },
      { value: 'Não', label: 'Não' }
    ],
    required: false
  },
  {
    label: 'Uplink',
    name: 'your-uplink',
    type: 'select',
    options: [
      { value: 'Copper', label: 'Copper' },
      { value: 'Fiber', label: 'Fiber' }
    ],
    required: false
  },
  {
    label: 'Minimum Uplink',
    name: 'your-minimum-uplink',
    type: 'select',
    options: [
      { value: '10Mb/s', label: '10Mb/s' },
      { value: '100Mb/s', label: '100Mb/s' },
      { value: '1,000Mb/s (GigE)', label: '1,000Mb/s (GigE)' },
      { value: '10,000Mb/s (10G)', label: '10,000Mb/s (10G)' }
    ],
    required: false
  },
  {
    label: 'Agendar uma Tour?',
    name: 'your-agendar-tour',
    type: 'select',
    options: [
      { value: 'Sim', label: 'Sim' },
      { value: 'Não', label: 'Não' }
    ],
    required: false
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledColocation}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <H4>
              <span>Colocation</span>
              Autonomia & Segurança
            </H4>
            <Paragraph>Este é o serviço mais adequado para suprir as necessidades de quem já possui servidores próprios, pois fornecemos toda a estrutura para abrigar e gerenciar os ativos de TI do seu negócio. Assim, os investimentos em manutenção, materiais e profissionais capacitados podem ser voltados para outros projetos da sua empresa.</Paragraph>
          </Column>
        </Row>
        <Row
          justifyContent='center'
          mb={50}
        >
          <Div
            maxWidth={['100%', 422, 680, 1032]}
          >
            <Column>
              <iframe width="100%" height="400" src="https://www.youtube.com/embed/YzSmXKcKm6I" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
            </Column>
          </Div>
        </Row>
        <Row
          mb={50}
        >
          <Div 
            flexDirection={['column', 'column', 'unset', 'unset']}
            css={IdProtectBox}>
            <Column
              width={[1, 1, 1/2, 1/2]}
            >
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Estrutura Certificada</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Alta disponibilidade</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Refrigeração de alta eficiência</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Monitoramento contínuo</span>
              </div>
            </Column>
            <Column
              width={[1, 1, 1/2, 1/2]}
            >
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Alta conectividade e rapidez</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Área de recepção</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Segurança impenetrável</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Acesso ao seu servidor</span>
              </div>
            </Column>
          </Div>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328, '100%', 856, 856]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          flexDirection={['column', 'row']}
          css={header}
          mt={32}
          mb={20}
        >
          <Column
            width={[1]}
          >
            <H4 style={{ textAlign: 'center' }}>Para a sua empresa</H4>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'column', 'row', 'row']}
          css={colocationItems}
          mb={50}
        >
          <Column>
            <div style={{ marginBottom: 30 }}>
              <H5>Área de recepção</H5>
              <Paragraph>O Data Center Nordeste da HostDime inclui uma área de recepção onde os clientes podem interagir e fazer a rede de acordo com sua conveniência.</Paragraph>
            </div>
            <div>
              <H5>Sala de colocation do cliente</H5>
              <Paragraph>Os clientes são encorajados a retirar seu próprio equipamento e trabalhar em seus servidores em um ambiente tranquilo, relaxante e sofisticado.</Paragraph>
            </div>
          </Column>
          <Column
            mt={[30, 30, 0, 0]}
          >
            <div>
              <H5>Visita ao Servidor</H5>
              <Paragraph>Tenha acesso ao seu servidor Colocation no data center HostDime. Desfrute de um passeio em nosso data center e tire o máximo de proveito das vantagens de ser um cliente HostDime.</Paragraph>
            </div>
          </Column>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328, '100%', 856, 856]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          flexDirection={['column', 'row']}
          css={header}
          mt={32}
        >
          <Column
            width={[1]}
          >
            <H4 style={{ marginBottom: 50, textAlign: 'center' }}>Solicite uma cotação<br /> de colocação</H4>
          </Column>
        </Row>
        <Row
          justifyContent={['center']}
          flexDirection={['column']}
          css={colocationForms}
        >
          <Column
            width={[1]}
          >
            <span
              style={{
                display: 'block',
                marginBottom: 16
              }}
            >
              Informações de contato
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <ColocationForm
                fields={fields}
                formId={27}
                submitText='enviar solicitação'
              />
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Colocation HostDime | Autonomia e segurança'
        description='Querendo reduzir o custo de manter servidores próprios? Conte com nossa infraestrutura, abrigue e gerencie os ativos de TI do seu negócio remotamente.'
      />
      <PageTemplate
        title='Colocation'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
